const travelTo = {
    init() {
        const anchors = document.querySelectorAll('.travel-to');
        const travelToSelects = document.querySelectorAll('.travel-to-select');
        const header = document.querySelector('header');
        const headerHeight = header.offsetHeight;

        if (anchors) {
            anchors.forEach(function(anchor) {
                const id = anchor.getAttribute('data-id');
                const block = document.getElementById(id);

                anchor.addEventListener('click', (e) => {
                    e.preventDefault();
                    let position = block.getBoundingClientRect();
                    let blockTop = position.top + window.scrollY - headerHeight;

                    window.scrollTo({
                        top: blockTop,
                        left: position.left,
                        behavior: 'smooth'
                    });
                });
            });
        }

        if (travelToSelects) {
            travelToSelects.forEach(function(select) {
                select.onchange = function(evt){
                    const id = select.value;
                    const block = document.getElementById(id);

                    let position = block.getBoundingClientRect();
                    let blockTop = position.top + window.scrollY - headerHeight;

                    window.scrollTo({
                        top: blockTop,
                        left: position.left,
                        behavior: 'smooth'
                    });
                };
            });
        }
    }
}

export default travelTo