/**
 * Main Script File
 */

import lozadInitiator from "./global/lozadInitiator";
import animations from "./global/animations";
import mobileMenu from "./global/mobileMenu";
import travelTo from "./global/travelTo";

lozadInitiator.init();
animations.init();
mobileMenu.init();
travelTo.init();

const header = document.querySelector('header');

window.addEventListener('scroll', () => {
    const currentScroll = window.scrollY;

    if (currentScroll <= 0) {
        header.classList.remove('header--sticky');
    } else {
        header.classList.add('header--sticky');
    }
});

let tabberAnchors = document.querySelectorAll('.locations .locations__tab-nav a');

tabberAnchors.forEach(function(tabberAnchor) {
    const tabID = tabberAnchor.getAttribute('data-tab-id');
    const targetTab = document.querySelector('#' + tabID);

    tabberAnchor.onclick = function(e){
        e.preventDefault();
        const otherTabAnchors = tabberAnchor.parentNode.querySelectorAll('a');
        const otherTabs = targetTab.parentNode.querySelectorAll('.locations__tab-box');

        otherTabAnchors.forEach(function (otherTabAnchors) {
            otherTabAnchors.classList.remove('active');
        });

        otherTabs.forEach(function (otherTab) {
            otherTab.classList.remove('active');
        });

        tabberAnchor.classList.add('active');
        targetTab.classList.add('active');
    };
});

const dateFilter = document.getElementById('date_filter');

if (dateFilter) {
    dateFilter.onchange = function(){
        document.location.href = this.value;
    };
}
