const animations = {
    init() {
        function animateValue(obj, start, end, duration) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                obj.innerHTML = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }

        function throttle(fn, ms) { // Ryan Taylor comment - https://remysharp.com/2010/07/21/throttling-function-calls
            var time, last = 0;
            return function() {
                var a = arguments, t = this, now = +(new Date), exe = function() { last = now; fn.apply(t, a); };
                clearTimeout(time);
                (now >= last + ms) ? exe() : time = setTimeout(exe, ms);
            }
        }

        function hasClass(el, cls) {
            if (el.className.match('(?:^|\\s)'+cls+'(?!\\S)')) { return true; }
        }

        function addClass(el, cls) {
            if (!el.className.match('(?:^|\\s)'+cls+'(?!\\S)')) { el.className += ' '+cls; }
        }

        function elementFromTop(elem, classToAdd, distanceFromTop, unit, delay, count) {
            var winY = window.innerHeight || document.documentElement.clientHeight,
                elemLength = elem.length, distTop, distPercent, distPixels, distUnit, i;

            for (let i = 0; i < elemLength; ++i) {
                distTop = elem[i].getBoundingClientRect().top;
                distPercent = Math.round((distTop / winY) * 100);
                distPixels = Math.round(distTop);
                distUnit = unit == 'percent' ? distPercent : distPixels;
                if (distUnit <= distanceFromTop) {
                    if (!hasClass(elem[i], classToAdd)) {
                        if (delay) {
                            setTimeout(function() {
                                addClass(elem[i], classToAdd);

                                if (count) {
                                    animateValue(elem[i], 0, elem[i].innerText, 1000)
                                }
                            }, delay * i);
                        } else {
                            addClass(elem[i], classToAdd);

                            if (count) {
                                animateValue(elem[i], 0, elem[i].innerText, 1000)
                            }
                        }
                    }
                }
            }
        }

        window.onload = function() {
            setTimeout(function() {
                const animationClasses = document.querySelectorAll('.fade-in, .slide-in-left, .slide-in-right, .lat-long');
                elementFromTop(animationClasses, 'active',  80, 'percent'); // as top of element hits top of viewport

                const countUp = document.querySelectorAll('.count-up');
                elementFromTop(countUp, 'active',  90, 'percent', 0, true); // as top of element hits top of viewport

                window.addEventListener('scroll', throttle(function() {
                    elementFromTop(animationClasses, 'active',  80, 'percent'); // as top of element hits top of viewport
                    elementFromTop(countUp, 'active',  90, 'percent', 0, true); // as top of element hits top of viewport
                }, 100), false);
            }, 60);
        };

        const rowLoad = document.querySelectorAll('.row-load');

        function sliceIntoChunks(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            return res;
        }

        for (let i = 0; i < rowLoad.length; i++) {
            // calc computed style
            const gridComputedStyle = window.getComputedStyle(rowLoad[i]);

            // items per row
            const perChunk = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ').length;

            // Convert htmlcollection to array
            var inputArray = [].slice.call(rowLoad[i].children);

            // Slice cols into rendered rows
            const rows = sliceIntoChunks(inputArray, perChunk);

            // loop the rows
            for (let r = 0; r < rows.length; r++) {
                elementFromTop(rows[r], 'active', 80, 'percent', 200);

                window.addEventListener('scroll', throttle(function() {
                    elementFromTop(rows[r], 'active',  80, 'percent', 200);
                }, 100), false);
            }
        }
    }
}

export default animations