const mobileMenu = {
    init() {
        const body = document.body;
        const header = document.querySelector('header');
        const mmToggle = document.querySelector('header .mm-toggle');
        const mobileMenu = document.querySelector('#mobile-menu');
        const openMenuClass = 'mobile-menu-open';

        if (mmToggle !== null) {
            mmToggle.addEventListener('click', () => {
                if (! mmToggle.classList.contains(openMenuClass)) {
                    const scrollY = window.scrollY;
                    body.style.position = 'fixed';
                    body.style.top = '-' + scrollY + 'px';
                } else {
                    const scrollY = body.style.top;
                    body.style.position = '';
                    body.style.top = '';
                    window.scrollTo(0, parseInt(scrollY || '0') * -1);
                }

                body.classList.toggle(openMenuClass);
                header.classList.toggle(openMenuClass);
                mobileMenu.classList.toggle(openMenuClass);
                mmToggle.classList.toggle(openMenuClass);
            });
        }

        document.querySelectorAll('.menu-item-has-children .menu-arrow').forEach(function(arrow) {
            arrow.onclick = function(){
                arrow.parentNode.classList.toggle('open');
                arrow.classList.toggle('open');
            };
        });
    }
}

export default mobileMenu